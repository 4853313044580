<template>
  <div id="page-logs">
    <b-card class="px-0">
      <h5>Info</h5>
      
      <b-textarea rows="20" v-model="out"></b-textarea>
      
      
    </b-card>

    <b-card class="px-0">

      <h5>Errors</h5>
      
      <b-textarea rows="20" v-model="errors"></b-textarea>

    </b-card>

  </div>
</template>

<script>

// axios is REST API
import axios from 'axios';

// popup messages in the corner
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

// vuex state, storage
import { mapGetters } from 'vuex';

export default {
  name: 'logs',
  mixins: [ toasts ],
  components: {
    
  },
  computed: {
    // which stored data we want to use
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'settings'])
  },
  mounted() {
    //this.example = this.examples[0];

    this.get_error_logs();
    this.get_out_logs();
  },
  data() {
    return {
      out: '',
      errors: '',
    };
  },
  watch: {
    currentCompanyId() {
      // triggers when user has changed company on top
      // we should reload content based on selected company
    },
    currentPeriodId() {
      // triggers when user has changed period on top
      // we should reload content based on period
    }

  },
  methods: {

    async get_error_logs() {
      try {
        const res = await axios.get(`/log/out/tail`);

        this.out = res.data.text;
      }
      catch (err) {
        console.error('error getting out', err);
      }
    },

    async get_out_logs() {
      try {
        const res = await axios.get(`/log/error/tail`);

        this.errors = res.data.text;
      }
      catch (err) {
        console.error('error getting errors', err);
      }
    }

  }
};
</script>
